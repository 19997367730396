<script setup lang="ts">
  // モーダルなYESNOダイアログ
  import { checkAppInBrowser, copyText } from "~/utils/Display";
  import { useSnackBarStore } from "~/stores/SnackBarStore";

  interface Props {
    isOpen: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    isOpen: false,
  });

  interface Emits {
    (event: "update:isOpen", isOpen: boolean): void;
  }

  const emit = defineEmits<Emits>();

  // スナックバーstore
  const snackBarStore = useSnackBarStore();

  const linkData = ref<{ type: string; url: string }>();

  const getLink = () => {
    const appType = checkAppInBrowser()!;

    const host = window.location.origin;
    const route = useRoute();
    let url = `${host}${route.path}`;
    let searchParams = queryToUrlSearchParams(route.query);

    if (route.path === "/login" && route.query.returnPath) {
      // ログイン画面の場合は、returnPathを取得
      const returnPath = route.query.returnPath ? (route.query.returnPath as string) : "/";
      // returnPathから pathとqueryを取得
      const returnPathUrl = new URL(returnPath, host);
      url = `${host}${returnPathUrl.pathname}`;
      searchParams = returnPathUrl.searchParams;
    }

    let type = "copy";

    if (appType?.includes("is_line")) {
      searchParams?.append("openExternalBrowser", "1");
      type = "jump";
    }

    return {
      type: type,
      url: `${url}` + (searchParams?.toString() ? `?${searchParams?.toString()}` : ""),
    };
  };

  const handleCopy = (url: string) => {
    copyText(url);
    snackBarStore.set("クリップボードにコピーしました");
  };

  const computedIsOpen = computed<boolean>({
    get: () => props.isOpen,
    set: value => {
      emit("update:isOpen", value);
    },
  });

  onMounted(() => {
    linkData.value = getLink();
  });
</script>

<template>
  <v-dialog v-model="computedIsOpen" scrim="black" opacity="0.8">
    <v-sheet class="dialog py-8 px-6 rounded" color="monochrome-04" align="center">
      <div>
        <AtomsFincsLogoSvg class="mb-2" :width="120" />
      </div>

      <template v-if="linkData?.type == 'jump'">
        <div>Fincsへアクセスしてご利用ください</div>
        <div class="d-flex justify-space-evenly">
          <a color="primary" block class="button bg-primary mt-4" :href="linkData?.url"> Fincsを開く </a>
        </div>
      </template>

      <template v-if="linkData?.type == 'copy'">
        <div>下のボタンを押して、URLをコピーし、SafariやGoogle Chromeなどのブラウザで開いてください</div>
        <div class="d-flex justify-space-evenly">
          <a color="primary" block class="button bg-primary mt-4" @click="handleCopy(linkData?.url)">
            URLをコピーする
          </a>
        </div>
      </template>
    </v-sheet>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .dialog {
    min-height: 146px;
    min-width: 345px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    white-space: break-spaces;
  }

  .button {
    padding: 4px 0 6px 0;
    font-size: 20px;
    border-radius: 4px;
    font-weight: 700;
    letter-spacing: 0.06em;
    text-decoration: none;
    color: white;
    width: 100%;
  }
</style>
